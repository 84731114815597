// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-departments-events-landing-js": () => import("./../../../src/templates/departments_events_landing.js" /* webpackChunkName: "component---src-templates-departments-events-landing-js" */),
  "component---src-templates-departments-news-landing-js": () => import("./../../../src/templates/departments_news_landing.js" /* webpackChunkName: "component---src-templates-departments-news-landing-js" */),
  "component---src-templates-page-contact-js": () => import("./../../../src/templates/page_contact.js" /* webpackChunkName: "component---src-templates-page-contact-js" */),
  "component---src-templates-page-directory-js": () => import("./../../../src/templates/page_directory.js" /* webpackChunkName: "component---src-templates-page-directory-js" */),
  "component---src-templates-page-disposal-guide-js": () => import("./../../../src/templates/page_disposal_guide.js" /* webpackChunkName: "component---src-templates-page-disposal-guide-js" */),
  "component---src-templates-page-documents-js": () => import("./../../../src/templates/page_documents.js" /* webpackChunkName: "component---src-templates-page-documents-js" */),
  "component---src-templates-page-events-js": () => import("./../../../src/templates/page_events.js" /* webpackChunkName: "component---src-templates-page-events-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-news-js": () => import("./../../../src/templates/page_news.js" /* webpackChunkName: "component---src-templates-page-news-js" */),
  "component---src-templates-page-pools-js": () => import("./../../../src/templates/page_pools.js" /* webpackChunkName: "component---src-templates-page-pools-js" */),
  "component---src-templates-pagelanding-js": () => import("./../../../src/templates/pagelanding.js" /* webpackChunkName: "component---src-templates-pagelanding-js" */),
  "component---src-templates-single-event-js": () => import("./../../../src/templates/single_event.js" /* webpackChunkName: "component---src-templates-single-event-js" */),
  "component---src-templates-single-member-js": () => import("./../../../src/templates/single_member.js" /* webpackChunkName: "component---src-templates-single-member-js" */),
  "component---src-templates-single-news-js": () => import("./../../../src/templates/single_news.js" /* webpackChunkName: "component---src-templates-single-news-js" */),
  "component---src-templates-sub-department-js": () => import("./../../../src/templates/sub_department.js" /* webpackChunkName: "component---src-templates-sub-department-js" */)
}

