/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
// const addScript = url => {
//   const script = document.createElement("script")
//   script.src = url
//   script.async = true
//   document.body.appendChild(script)
// }


exports.onPreRouteUpdate = ({location, prevLocation}) => {

	var oldPath = prevLocation ? prevLocation.pathname : null;

	if(typeof _st === 'function' && location.pathname !== oldPath) {
		delete window._st
	}

	(function(w,d,t,u,n,s,e){w['SwiftypeObject']=n;w[n]=w[n]||function(){
	(w[n].q=w[n].q||[]).push(arguments);};s=d.createElement(t);
	e=d.getElementsByTagName(t)[0];s.async=1;s.src=u;e.parentNode.insertBefore(s,e);
	})(window,document,'script','//s.swiftypecdn.com/install/v2/st.js','_st');
	window._st('install','wN_qy_h-xs6B97E3gJbp','2.0.0')

}